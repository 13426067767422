import React, { FC, useContext } from 'react';
import { InsuranceContainerContext } from 'View/Container/InsuranceContainer/InsuranceContainerContext';
import { useInsuranceFormType } from 'View/HookApi/useInsuranceFormType';

export type TFormViewProps = {
  children: React.ReactNode;
  className?: string;
};

export const FormView: FC<TFormViewProps> = ({ children, className = '' }) => {
  const { b2bClientKey } = useContext(InsuranceContainerContext);
  const formType = useInsuranceFormType({ b2bClientKey });

  if (formType !== 'FormView') {
    return null;
  }

  return (
    <div className={`form-view ${className}`}>
      {children}
    </div>
  );
};
