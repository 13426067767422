import { StaticPageName } from 'api/graphql';
import { FormikErrors, FormikHandlers, FormikValues, useFormik } from 'formik';
import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { insuranceFormRequiredFields } from 'utils/Constants/InsuranceFormRequiredFields';
import ErrorFocus from 'utils/Formik_Error_Focus';
import { isValidEmail, isValidInsuranceNumber } from 'utils/validations';
import { Button, TFormFieldProps } from 'View/Common';
import { HealthRequirementsModal } from 'View/Component/Modals/HealthRequirementsModal/HealthRequirementsModal';
import { MarkdownModal } from 'View/Component/Modals/MarkdownModal/MarkdownModal';

interface IRegister {
  handleChange: FormikHandlers['handleChange'];
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
}

interface IFormChildrenProps {
  register: (name: string) => TFormFieldProps;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
}

export type TFormProps = {
  className?: string;
  children: ({ register, values, errors }: IFormChildrenProps) => ReactNode;
  defaultValues?: Record<string, unknown>;
  additionalFields?: string[];
};

export const Form: FC<TFormProps> = ({ defaultValues = {}, additionalFields = [], className = '', children }) => {
  const [showHealthRequirements, setShowHealthRequirements] = useState(false); // Schritt 2
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const _register = useCallback(
    ({ handleChange, values, errors }: IRegister) =>
      (name: string) => {
        const fieldProps: Record<string, any> = {
          type: 'input',
          label: undefined as string | ReactNode,
        };

        switch (name) {
          case 'name': {
            fieldProps.label = 'Name';
            fieldProps.placeholder = 'Vor- und Nachname*';
            fieldProps.required = true;
            break;
          }
          case 'email': {
            fieldProps.label = 'E-Mail';
            fieldProps.placeholder = 'E-Mail*';
            fieldProps.required = true;
            break;
          }
          case 'insuranceNumber': {
            fieldProps.label = 'Versichertennummer';
            fieldProps.placeholder = 'Versichertennummer*';
            fieldProps.required = true;
            break;
          }
          case 'dateOfBirth': {
            fieldProps.label = 'Geburtsdatum';
            fieldProps.placeholder = 'Geburtsdatum* (TT.MM.JJJJ)';
            fieldProps.required = true;
            break;
          }
          case 'age': {
            fieldProps.label = 'Geburtsdatum';
            fieldProps.placeholder = 'Alter';
            break;
          }
          case 'healthCheck': {
            fieldProps.type = 'checkbox';
            fieldProps.label = 'Health Checkbox';
            break;
          }
          case 'termsCheck': {
            fieldProps.type = 'checkbox';
            fieldProps.label = 'Terms Checkbox';
            break;
          }
          case 'precondition': {
            fieldProps.type = 'checkbox';
            fieldProps.label = 'PreConditions Checkbox';
            break;
          }
          case 'gender': {
            fieldProps.label = 'Geschlecht';
            fieldProps.placeholder = 'Geschlecht';
            fieldProps.type = 'select';
            fieldProps.options = ['weiblich', 'männlich', 'divers'];
            break;
          }
          case 'healthPreconditions': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label = (
              <>
                'Ich akzeptiere die{' '}
                <Button
                  title="öffnet ein Fenster mit dem gesundheitlichen Voraussetzungen"
                  type="link"
                  className="form-regulatory-link"
                  onClick={() => setShowHealthRequirements(true)}
                >
                  Gesundheitsvoraussetzungen
                </Button>{' '}
                und bestätige hiermit, dass keine Kontraindikationen für die Absolvierung des Kurses vorliegen.'
              </>
            );
            break;
          }
          case 'legalConditions': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label = (
              <>
                'Ich akzeptiere die{' '}
                <Button
                  title="öffnet ein Fenster mit den AGBs"
                  type="link"
                  className="form-regulatory-link"
                  onClick={() => setShowTerms(true)}
                >
                  AGB
                </Button>{' '}
                und die Hinweise zum{' '}
                <Button
                  title="öffnet ein Fenster mit den Datenschutzbedingungen"
                  type="link"
                  className="form-regulatory-link"
                  onClick={() => setShowPrivacy(true)}
                >
                  Datenschutz
                </Button>
                .'
              </>
            );
            break;
          }
          case 'dataTransfer': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label =
              'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die (#Krankenkassenname#) übermittelt werden.';
            break;
          }
          case 'noMoreCourses': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label =
              'Hiermit bestätige ich, dass ich in diesem Kalenderjahr noch nicht an zwei Präventionskursen teilgenommen habe.';
            break;
          }
          case 'successfulCourse': {
            fieldProps.type = 'toggle';
            fieldProps.required = true;
            fieldProps.label =
              'Mir ist bewusst, dass die Übernahme der Kosten durch meine Krankenkasse (#Krankenkassenname#) auf einem erfolgreichen Kursabschluss beruht.';
            break;
          }
        }

        return {
          id: name,
          name,
          value: values[name],
          errorMessage: errors?.[name],
          onChange: handleChange,
          ...fieldProps,
        };
      },
    [],
  );

  const _fields = useMemo(() => {
    return [
      ...additionalFields,
      'name',
      'email',
      'insuranceNumber',
      'dateOfBirth',
      'healthPreconditions',
      'legalConditions',
      'dataTransfer',
      'noMoreCourses',
      'successfulCourse',
      'gender',
    ];
  }, []);

  const validation = (values: FormikValues): FormikErrors<FormikValues | undefined> => {
    const errors: Record<string, string> = {};

    _fields.forEach((field) => {
      if (!values[field]) {
        errors[field] = (insuranceFormRequiredFields[field] || 'Feld wird benötigt') as string;
      }
      switch (field) {
        case 'email': {
          if (isValidEmail(values[field])) {
            errors[field] = 'E-Mail ist ungültig';
          }
          break;
        }
        case 'insuranceNumber': {
          if (isValidInsuranceNumber(values[field])) {
            errors[field] = 'Versichertennummer ist ungültig';
          }
          break;
        }
        case 'dateOfBirth': {
          if (!values[field]) {
            errors[field] = 'Geburtsdatum wird benötigt.';
          }
          break;
        }
        case 'healthPreconditions': {
          if (!values[field]) {
            errors[field] = 'Bitte akzeptiere die Gesundheitsvoraussetzungen.';
          }
          break;
        }
        case 'legalConditions': {
          if (!values[field]) {
            errors[field] = 'Bitte akzeptiere die AGB und Hinweise zum Datenschutz.';
          }
          break;
        }
        case 'dataTransfer': {
          if (!values[field]) {
            errors[field] = 'Bitte akzeptiere die Datenweitergabe.';
          }
          break;
        }
        case 'noMoreCourses': {
          if (!values[field]) {
            errors[field] = 'Bitte bestätige dein Präventionsbudget.';
          }
          break;
        }
        case 'successfulCourse': {
          if (!values[field]) {
            errors[field] = 'Bitte bestätige, dies zur Kenntniss genommen zu haben.';
          }
          break;
        }
        case 'gender': {
          if (!values[field]) {
            errors[field] = 'Geschlecht wird benötigt.';
          }
          break;
        }
      }
    });

    if (Object.values(errors).some((item) => item?.length)) {
      return errors;
    }

    return undefined;
  };

  const { values, errors, isSubmitting, isValidating, handleChange, handleSubmit } = useFormik({
    initialValues: defaultValues,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: validation,
  });

  return (
    <form className={`insurance-form ${className}`} onSubmit={handleSubmit}>
      {children({
        register: _register({ values, errors, handleChange }),
        values,
        errors,
      })}
      <ErrorFocus isSubmitting={isSubmitting} isValidating={isValidating} errors={errors} />
      <HealthRequirementsModal open={showHealthRequirements} onClose={() => setShowHealthRequirements(false)} />
      <MarkdownModal open={showTerms} onClose={() => setShowTerms(false)} pageName={StaticPageName.TermsAndConditions} />
      <MarkdownModal open={showPrivacy} onClose={() => setShowPrivacy(false)} pageName={StaticPageName.PrivacyPolicy} />
    </form>
  );
};
