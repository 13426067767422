import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { globalHistory, Router } from '@reach/router';
import React, { useEffect } from 'react';
import { ROUTES } from 'utils/typings/routes';
import { Store, useStore } from 'utils/store';

import Imprint from 'views/Imprint';
import Contact from 'views/Contact';
import Home from 'views/Home/Home';
import NotFound from 'views/NotFound';
import AppLinkNotFound from 'views/AppLinkNotFound';
import Pricing from 'views/Payment/Pricing/Pricing';
import Privacy from 'views/Privacy';
import Terms from 'views/Terms';
import BillingData from 'views/Payment/BillingDetails/BillingDetails';
import PaymentMethod from 'views/Payment/PaymentMethod/PaymentMethod';
import Confirmation from 'views/Payment/Confirmation';
import Summary from 'views/Payment/Summary/Summary';
import LoginConfirm from 'views/LoginConfirm';
import UnkownUserBuy from 'views/Payment/UnkownUserBuy';
import ActivationLink from 'views/ActivationLink';
import PaymentError from 'views/Payment/PaymentError';
import ResendToken from 'views/Payment/Pricing/ResendToken/ResendToken';
import { IWindow } from 'utils/typings/window';
import AlreadyPaying from 'views/Payment/AlreadyPaying';
import XingLandingpage from 'views/B2BLandingPages/XingLandingpage';
import { ZppLandingPage } from 'View/Page/ZppLandingPage/ZppLandingPage';
import XingProJobsLandingpage from 'views/B2BLandingPages/XingProJobsLandingpage';
import RedeemVoucherSuccess from 'views/RedeemVoucherSuccess';
import VoucherLandingpage from 'views/B2BLandingPages/VoucherLandingpage';
import TerminateForm from 'views/Terminate/Form/TerminateForm';
import GothaerLandingpage from 'views/B2BLandingPages/GothaerLandingpage';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { environment } from 'utils/environment';
import public_keys from 'public_keys';
import TerminateSuccess from 'views/Terminate/TerminateSuccess';
import * as process from 'process';
import { platform, VERSION } from './config';
import { BigLandingPage } from './View/Page/BigLandingPage/BigLandingPage';
import { themeSleep, ThemeProvider } from 'View/Theme';

const httpLink = createHttpLink({
  uri: `${
    process.env.BYPASS_PROXY === 'true' ? process.env.API_ORIGIN : process.env.PROXY_ORIGIN ? process.env.PROXY_ORIGIN : ''
  }/api/graphql`,
});

// not needed anymore if staticPages and offers
// queries are public
const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: process.env.TOKEN ?? '',
    },
  };
});

const client = new ApolloClient({
  // @ts-expect-error https://github.com/apollographql/apollo-link/issues/538 problem with codegen, it use a older apollo-link and typescript don't know about the correct type
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

declare const window: IWindow;

// TODO: use process.env once the website can reference env vars at runtime
if (['staging', 'production'].includes(environment)) {
  try {
    Bugsnag.start({
      apiKey: public_keys.BUGSNAG_KEY_WEBSITE ?? '',
      plugins: [new BugsnagPluginReact()],
      releaseStage: environment,
      appVersion: VERSION,
    });
  } catch (error) {
    console.error(error);
  }
}

const App: React.FC = () => {
  const [store, dispatch] = useStore();

  useEffect(() => {
    if (!window.dataLayer) {
      const { href, origin, pathname } = window.location;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Pageview-React',
        pagePath: { href, origin, pathname },
      });
    }
    return globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        window.dataLayer.push({ event: 'Pageview-React', pagePath: location });
      }
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={themeSleep}>
        <Store.Provider value={[store, dispatch]}>
          <Router>
            <Home path={ROUTES.HOME} />
            <Contact path={ROUTES.CONTACT} />
            <Terms path={ROUTES.TERMS} />
            <Imprint path={ROUTES.IMPRINT} />
            <Privacy path={ROUTES.PRIVACY} />
            <LoginConfirm path={ROUTES.LOGIN_CONFIRMATION} />
            <ActivationLink path={ROUTES.ACTIVATION_LINK} />
            <Pricing path={ROUTES.PRICING} />
            <BillingData path={ROUTES.BILLING_DETAILS} />
            <PaymentMethod path={ROUTES.PAYMENT_METHOD} />
            <Summary path={ROUTES.SUMMARY} />
            <Confirmation path={ROUTES.CONFIRMATION} />
            <UnkownUserBuy path={ROUTES.CONFIRMATION_UNKNOWN} />
            <PaymentError path={ROUTES.PAYMENT_ERROR} />
            <AlreadyPaying path={ROUTES.ALREADY_PAYING} />
            <ResendToken path={ROUTES.RESEND_TOKEN} />
            <ResendToken path={ROUTES.RESEND_TOKEN_B2B} />
            <AppLinkNotFound path={ROUTES.CATEGORIES_FALLBACK} />
            <AppLinkNotFound path={ROUTES.GROUPS_FALLBACK} />
            <AppLinkNotFound path={ROUTES.SLEEP_FALLBACK} />
            <XingLandingpage path={ROUTES.XING_PREMIUM} />
            {process.env.REACT_APP_ZPP_APP === '1' && <ZppLandingPage path={ROUTES.ZPP_LANDING_PAGE} />}
            {!platform.isProduction && <BigLandingPage path={ROUTES.BIG_LANDINGPAGE} />}
            <XingProJobsLandingpage path={ROUTES.XING_PRO_JOBS} />
            <GothaerLandingpage path={ROUTES.GOTHAER} />
            <RedeemVoucherSuccess path={ROUTES.VOUCHER_SUCCESS} />
            <VoucherLandingpage path={ROUTES.VOUCHER_LANDINGPAGE} />
            <TerminateForm path={ROUTES.TERMINATE} />
            <TerminateSuccess path={ROUTES.TERMINATE_SUCCESS} />
            <NotFound default />
          </Router>
        </Store.Provider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
