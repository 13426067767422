import styled from 'styled-components';
import { asRem } from 'utils/asRem';
import { mediaQueries } from 'utils/Constants/Breakpoints';

export default styled.div`
  padding-top: ${asRem(40)};
  width: 100%;
  background-color: transparent;
  margin-bottom: var(--sleep-gutter-xs);

  ${mediaQueries.md} {
    padding-top: var(--sleep-gutter-xl);
    border-radius: ${asRem(10)};
    background-color: var(--sleep-blue-c770);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: var(--sleep-gutter-xl);
  }

  .insurance-form {
    padding-top: ${asRem(40)};
    padding-bottom: 0;
    text-align: left;
    display: inline-block;

    ${mediaQueries.md} {
      margin: 0 auto;
      overflow: hidden;
    }
  }
`;
