import React, { FC, ReactNode } from 'react';
import Style from './InsuranceContainer.style';
import { InsuranceContainerContext } from './InsuranceContainerContext';
import {
  Form, TFormProps,
  FormView, TFormViewProps
} from 'View/Component/InsuranceForm/InsuranceContainer';

type TProps = {
  b2bClientKey: string;
  children: ReactNode;
} & {
  FormView?: FC<TFormViewProps>;
  Form?: FC<TFormProps>;
};

export function InsuranceContainer({ b2bClientKey, children }: TProps) {
  return (
    <InsuranceContainerContext.Provider value={{ b2bClientKey }}>
      <Style className="insuranceContainer">
        {children}
      </Style>
    </InsuranceContainerContext.Provider>
  );
};

InsuranceContainer.FormView = FormView;
InsuranceContainer.Form = Form;

