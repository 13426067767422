import React, { FunctionComponent } from 'react';
import { IImageProps } from '@missionme/mime_elements/dist/esm/Components';
import { Image as MiMeImage } from '@missionme/mime_elements';

type TProps = {
  src?: string;
  image?: string;
  srcSet?: any;
  height?: number | string;
  width?: number | string;
  alt?: string;
  title?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const Image: FunctionComponent<TProps> = ({ src, image, srcSet, title, ...props }) => {

  const imageProps: IImageProps = {
    ...props,
    src: (src || image) as string,
    srcSet: srcSet as string
  };

  if(srcSet) {
    imageProps.srcSet = Object.keys(srcSet).map((key) => `${srcSet[key]} ${key}`).join(', ');
  }

  if(title) {
    return <span title={title}><MiMeImage { ...imageProps } /></span>;
  }
  console.log(imageProps);
  return (
    <MiMeImage { ...imageProps } />
  );
};
